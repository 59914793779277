import React, { Component } from 'react';

import { globalHistory } from '@reach/router';

import SafeAreaWrapper from '../components/SafeAreaWrapper';
import Navigation from '../components/Navigation';

// import packages from '../packages.json';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			scrolling: false,
			showDropdown: false,
			dropdownContent: null,
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		// this.setState({
		// 	showDropdown: false,
		// });

		this.historyUnsubscribe = globalHistory.listen(({ action }) => {
			if (action === 'PUSH') {
				this.setState({
					showDropdown: false,
				});
			}
		});

		// Using Hooks
		// useEffect(() => {
		//     return globalHistory.listen(...);
		// }, []);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		this.historyUnsubscribe();
	}

	handleScroll = event => {
		// this.setState({ scrolling: window.scrollY !== 0 });

		let isScrolling = window.scrollY !== 0;
		if (this.state.scrolling !== isScrolling) {
			this.setState({ scrolling: isScrolling });
		}
	};

	toggleDropdown = () => {
		this.setState(state => ({
			showDropdown: !state.showDropdown,
		}));
	};

	setDropdownContent = content => {
		this.setState({ dropdownContent: content });
	};

	render() {
		const { siteTitle, siteDescription, translucent } = this.props;
		const { showDropdown } = this.state;

		return (
			<>
				<header
					style={{ transition: 'all .3s' }}
					className={`w-full z-50 fixed top-0 left-0 right-0 overflow-y-auto ${
						!this.state.showDropdown && translucent && !this.state.scrolling
							? 'bg-opacity-0'
							: 'bg-opacity-100 bg-white shadow-md'
					}`}>
					<div className="container mx-auto px-20 md:px-0">
						<SafeAreaWrapper>
							<Navigation
								translucent={
									!this.state.showDropdown && translucent && !this.state.scrolling
								}
								toggleDropdown={this.toggleDropdown}
								isDroppedDown={this.state.showDropdown}
								setDropdownContent={this.setDropdownContent}
							/>
						</SafeAreaWrapper>
					</div>
					{this.state.showDropdown && (
						<>
							<div>{this.state.dropdownContent}</div>
							{/* <div
							onClick={e => {
								this.toggleDropdown();
							}}
							className="fixed bg-jetblack bg-opacity-50 w-full h-full"
						/> */}
						</>
					)}
				</header>
			</>
		);
	}
}

export default Header;
