import React from 'react';
import { Link } from 'gatsby';

import { Secondary as SecondaryButton } from './Button';

import Solutions from './Solutions';

import LogoImage from '../svg/logo.svg';
import ArrowHeadFatDownImage from '../svg/arrowhead-fat-down.svg';

const Navigation = ({ translucent, toggleDropdown, isDroppedDown, setDropdownContent }) => {
	function handleToggleDropdown(e, content) {
		e.preventDefault();
		setDropdownContent(content);
		toggleDropdown();
	}

	return (
		<nav className="flex items-center justify-start flex-wrap select-none">
			<div className="flex items-center flex-grow-0 flex-shrink-0">
				<Link to="/">
					<LogoImage
						alt="Hanseaticsoft Logo"
						className="p-10 md:p-20 -ml-10 md:-ml-20 bg-white box-content"
						style={{
							width: '42px',
							height: '42px',
							borderBottomLeftRadius: '6px',
							borderBottomRightRadius: '6px',
						}}
					/>
				</Link>
			</div>

			<ul className="hidden lg:flex flex-grow">
				<li className="ml-50 mr-40">
					<a
						onClick={e => handleToggleDropdown(e, <Solutions />)}
						className={`cursor-pointer flex items-center ${
							translucent ? 'text-white' : 'text-nevada'
						}`}>
						Solutions
						<span className="ml-4">
							<ArrowHeadFatDownImage
								className={`fill-current transform duration-100 ${
									isDroppedDown ? 'rotate-180' : 'rotate-0'
								}`}
							/>
						</span>
					</a>
				</li>
				<li className="mr-40">
					<Link
						to="/new-features"
						className={`flex items-center border-transparent border-solid border-b-2 pb-2 ${
							translucent ? 'text-white' : 'text-nevada'
						}`}
						activeClassName={`flex items-center border-solid border-b-2 pb-2 ${
							translucent
								? 'text-white border-white'
								: 'text-nevada border-pictonblue'
						}`}>
						New features
					</Link>
				</li>
				<li className="mr-40">
					<a
						className={`cursor-not-allowed flex items-center ${
							translucent ? 'text-white' : 'text-nevada'
						}`}
						href="#">
						Customers
					</a>
				</li>
				<li className="mr-40">
					<Link
						to="/blog"
						partiallyActive={true}
						className={`flex items-center border-transparent border-solid border-b-2 pb-2 ${
							translucent ? 'text-white' : 'text-nevada'
						}`}
						activeClassName={`flex items-center border-solid border-b-2 pb-2 ${
							translucent
								? 'text-white border-white'
								: 'text-nevada border-pictonblue'
						}`}>
						Blog
					</Link>
				</li>
				{/* <li className="mr-40">
						<a
							className={`cursor-not-allowed flex items-center ${
								translucent && !this.state.scrolling ? 'text-white' : 'text-nevada'
							}`}
							href="#">
							FAQ
						</a>
					</li> */}
				<li className="">
					<a
						className={`cursor-not-allowed flex items-center ${
							translucent ? 'text-white' : 'text-nevada'
						}`}
						href="#">
						Contact
					</a>
				</li>
			</ul>

			<div className="hidden lg:inline">
				<SecondaryButton>Schedule a demo</SecondaryButton>
			</div>
		</nav>
	);
};

export default Navigation;
