import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SafeAreaWrapper from './SafeAreaWrapper';

import Card from '../components/Card';

const Badge = () => (
	<span className="bg-pictonblue px-12 py-6 rounded-full text-white text-xs ml-10">New</span>
);

export default () => {
	const [storeWindow, setStoreWindow] = useState('bg-iron');

	return (
		<StaticQuery
			query={graphql`
				query HeadingQuery {
					productPortfolioImage: file(relativePath: { eq: "product-portfolio.png" }) {
						childImageSharp {
							fluid(quality: 60, maxWidth: 430) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			`}
			render={data => (
				<div className={`bg-porcelain h-screen`}>
					<div className="container mx-auto px-20 md:px-0">
						<SafeAreaWrapper>
							<nav className="py-50 grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-y-30 md:gap-y-60 select-none text-jetblack">
								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud Organisation
									</h3>
									<ul>
										<li
											onMouseEnter={() => setStoreWindow('bg-pictonblue')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Portal
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-black')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											CFM Go
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-atlantis')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Partner Portal
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-tealblue')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Employees
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-tealblue')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Files <Badge />
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-pictonblue')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Users
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-tealblue')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Companies
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-atlantis')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Vessel Particulars
										</li>
										<li
											onMouseEnter={() => setStoreWindow('bg-solaris')}
											onMouseLeave={() => setStoreWindow('bg-iron')}
											className="mb-10 hover:text-pictonblue cursor-pointer transition-colors duration-300">
											Ports & Yards
										</li>
									</ul>
								</div>
								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud QHSE
									</h3>
									<ul>
										<li className="mb-10">Circulars</li>
										<li className="mb-10">
											Emergencies <Badge />
										</li>
										<li className="mb-10">
											IHM <Badge />
										</li>
										<li className="mb-10">Incidents</li>
										<li className="mb-10">Inspections & Audits</li>
										<li className="mb-10">Inspection Report</li>
										<li className="mb-10">Procedures</li>
										<li>Risk Assessment</li>
									</ul>
								</div>
								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud Operation
									</h3>
									<ul>
										<li className="mb-10">Charter</li>
										<li className="mb-10">Off-hire & Claims</li>
										<li className="mb-10">Schedules & Agents</li>
										<li>World Map</li>
									</ul>
								</div>

								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud Technical
									</h3>
									<ul>
										<li className="mb-10">Blog</li>
										<li className="mb-10">Certificates</li>
										<li className="mb-10">Disturbance Report</li>
										<li>MARPOL Record Book</li>
									</ul>
								</div>

								{/* <div
									className={`col-span-2 row-span-2 rounded hidden lg:block transition-colors duration-300`}>
									<Card
										category="Brochure"
										title="Cloud Fleet Manager Product Portfolio"
										text="Learn in depth what Cloud Fleet Manager has to offer and how the cloud-based approach helps
            shipping companies."
										image={data.productPortfolioImage}
										button="Download"
										className="shadow-md"
									/>
								</div> */}

								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud Crewing
									</h3>
									<ul>
										<li className="mb-10">Crewing</li>
										<li className="mb-10">
											Accomodation <Badge />
										</li>
										<li className="mb-10">
											Crew Portal <Badge />
										</li>
										<li className="mb-10">Cashbox & Bonded Store</li>
										<li className="mb-10">Medical Logbook</li>
										<li>Pharmacy</li>
									</ul>
								</div>
								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud Efficiency
									</h3>
									<ul>
										<li className="mb-10">Bunker</li>
										<li className="mb-10">Event Reporting</li>
										<li className="mb-10">MRV & IMO DCS</li>
										<li className="hover:text-pictonblue cursor-pointer transition-colors duration-300">
											CFM PowerBI Integration <Badge />
										</li>
									</ul>
								</div>

								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud Purchase
									</h3>
									<ul>
										<li className="mb-10">Purchase</li>
										<li className="mb-10">Purchase Mobile</li>
										<li>Inventory</li>
									</ul>
								</div>

								<div>
									<h3 className="mb-20 font-medium tracking-widest text-xs text-nevada uppercase">
										Cloud Maintenance
									</h3>
									<ul>
										<li>Maintenance</li>
									</ul>
								</div>
							</nav>
						</SafeAreaWrapper>
					</div>
				</div>
			)}
		/>
	);
};
