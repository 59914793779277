import React from 'react';

const Primary = props => (
	<button className="inline-block bg-pictonblue hover:bg-tealblue transition-color duration-300 ease-in-out focus:outline-none text-white uppercase font-bold py-16 px-40 rounded-full tracking-wider">
		{props.children}
	</button>
);

const Secondary = props => (
	<button className="inline-block bg-solarisdark hover:bg-solaris transition-color duration-300 ease-in-out focus:outline-none text-white uppercase font-bold py-16 px-40 rounded-full tracking-wider">
		{props.children}
	</button>
);

export { Primary, Secondary };
