import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';

const Layout = ({ translucent, children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					description
				}
			}
		}
	`);
	return (
		<>
			<div className="relative">
				<Header
					translucent={translucent}
					siteTitle={data.site.siteMetadata.title}
					siteDescription={data.site.siteMetadata.description}
				/>

				<main className="w-full relative">{children}</main>

				<Footer />
			</div>
		</>
	);

	//   return <div style={{ margin: `0 auto`, maxWidth: 650, padding: `0 1rem` }}>{children}</div>;
};

Layout.defaultProps = {
	translucent: false,
};

export default Layout;
