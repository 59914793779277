import React from 'react';

const SafeAreaWrapper = ({ className, children }) => (
	<div
		className={className}
		style={{
			paddingTop: `env(safe-area-inset-top)`,
			paddingRight: `env(safe-area-inset-right)`,
			paddingLeft: `env(safe-area-inset-left)`,
		}}>
		{children}
	</div>
);

export default SafeAreaWrapper;
