import React from 'react';

import Img from 'gatsby-image';

import { Primary as PrimaryButton } from 'components/Button';

export default ({ category, title, text, image, button, className }) => (
	<div
		className={`flex flex-col bg-porcelain rounded-large overflow-hidden relative z-10 ${className}`}>
		<div className="overflow-hidden relative z-10">
			{image && (
				<Img
					fluid={image.childImageSharp.fluid}
					alt={title}
					className="w-full h-240 object-cover cursor-pointer duration-500 transform hover:scale-125 z-0"
				/>
			)}
		</div>

		<div className="px-30 py-40 flex flex-col items-center justify-center">
			{category && <h4 className="font-bold text-pictonblue uppercase mb-12 ">{category}</h4>}
			<h3 className="mb-12 text-center">{title}</h3>
			<p className="small text-center">{text}</p>
			{button && (
				<div className="mt-24">
					<PrimaryButton>{button}</PrimaryButton>
				</div>
			)}
		</div>
	</div>
);
